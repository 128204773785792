import type {Plugin} from '../../branding';
import {logger} from '../../logger';

// List of valid sandbox values for plugins. We restrict the usage of sandbox values to a subset of the full list of available values.
const VALID_SANDBOX_VALUES = [
    'allow-scripts',
    'allow-same-origin',
    'allow-popups',
    'allow-popups-to-escape-sandbox',
];

// 'allow-scripts' is a default sandbox value for plugins
const defaultSandboxValues = ['allow-scripts'];

const filterValidSandboxValues = (sandboxValues: string[]): string[] => {
    // Combine provided sandbox values with default sandbox values and eliminate duplicates using a Set
    const combinedSandboxValues = Array.from(
        new Set([...sandboxValues, ...defaultSandboxValues]),
    );
    // Filter out invalid values and log warnings
    return combinedSandboxValues.filter(value => {
        const isValid = VALID_SANDBOX_VALUES.includes(value);
        if (!isValid) {
            logger.warn(
                `Warning: '${value}' is not a valid sandbox value for plugins.`,
            );
        }
        return isValid;
    });
};

export const getValidSandboxValues = (plugin: Plugin): string => {
    const validSandboxValues = filterValidSandboxValues(
        plugin.sandboxValues ?? [],
    );
    return validSandboxValues.join(' ');
};
