import {useMatchMedia} from '@pexip/hooks';

import {
    MOBILE_DEVICE_PORTRAIT,
    MOBILE_DEVICE_LANDSCAPE,
} from '../../constants/mediaQueries';

export const useMobileDevice = () => {
    const isPortrait = useMatchMedia(MOBILE_DEVICE_PORTRAIT);
    const isLandscape = useMatchMedia(MOBILE_DEVICE_LANDSCAPE);
    return isPortrait || isLandscape;
};
