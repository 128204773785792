import React from 'react';
import cx from 'classnames';

import type {UserMediaStatus} from '@pexip/media';
import {isInitialPermissionsNotGranted} from '@pexip/media';

import {TestId} from '../../../../test/testIds';
import {VideoStatus} from '../../VideoStatus/VideoStatus.view';
import {useDeviceStatusInfo} from '../../../hooks/useDeviceStatusInfo';
import type {SelfviewAspectRatios} from '../../../types';
import {isVideoMuted} from '../SelfView.utils';
import type {SelfviewProps} from '../Selfview.view';
import {AspectRatioSelfview} from '../AspectRatioSelfview/AspectRatioSelfview';

import styles from './PreflightSelfview.module.scss';

export const PreflightSelfview: React.FC<
    Omit<SelfviewProps, 'shouldShowUserAvatar'> & {
        aspectRatioType?: SelfviewAspectRatios;
        isMobileDevice?: boolean;
        shouldShowBorder: boolean;
        streamStatus: UserMediaStatus | undefined;
        preflightControls?: React.ReactElement;
        requestedAudio?: boolean;
        requestedVideo?: boolean;
    }
> = props => {
    const {
        aspectRatioType = 'camera-step',
        isMobileDevice = false,
        isVideoInputMuted,
        shouldShowErrorBorder,
        streamStatus,
        preflightControls,
        requestedVideo = true,
        requestedAudio = true,
        ...restProps
    } = props;
    const inputStatusInfo = useDeviceStatusInfo(props.streamStatus, {
        audio: requestedAudio,
        video: requestedVideo,
    });
    const shouldShowUserAvatar =
        isVideoMuted(isVideoInputMuted, streamStatus) || !requestedVideo;
    const shouldShowVideoStatus =
        requestedVideo &&
        streamStatus &&
        isInitialPermissionsNotGranted(streamStatus);

    return (
        <div className={styles.preflightSelfViewWrapper}>
            <AspectRatioSelfview
                isVideoInputMuted={isVideoInputMuted}
                {...restProps}
                shouldShowUserAvatar={shouldShowUserAvatar}
                shouldShowErrorBorder={shouldShowErrorBorder}
                className={cx({
                    [styles.hideBackground]:
                        isMobileDevice && shouldShowVideoStatus,
                })}
                aspectRatioType={aspectRatioType}
            >
                {shouldShowVideoStatus && (
                    <VideoStatus
                        title={inputStatusInfo.title}
                        body={inputStatusInfo.message}
                        data-testid={TestId.VideoStatus}
                        isPhone={isMobileDevice}
                    />
                )}
                {preflightControls}
            </AspectRatioSelfview>
        </div>
    );
};

export type PreflightSelfviewProps = React.ComponentProps<
    typeof PreflightSelfview
>;
