import type {Url, BuildUrlOptions} from '@pexip/router';
import {formatPath, createRouter, createRouterHooks} from '@pexip/router';
import {createSignal} from '@pexip/signal';

import {logger} from './logger';
import {validateDisconnectDestination} from './utils/validateDisconnectDestination';

const baseUri = new URL(document.baseURI).pathname;

const urlRequestSignal = createSignal<Url>({name: 'router:urlRequest'});
const urlChangedSignal = createSignal<Url>({name: 'router:urlChanged'});

export const router = createRouter({
    baseUri,
    urlRequestSignal,
    urlChangedSignal,
});

export const {useRouter, createRoutesHook, createSubRoutesHook} =
    createRouterHooks(router);

export const push = (
    url: Url | string,
    {base = baseUri, ...rest}: BuildUrlOptions = {},
) => router.history.push(url, {base, ...rest});

export const replace = (
    url: Url | string,
    {base = baseUri, ...rest}: BuildUrlOptions = {},
) => router.history.replace(url, {base, ...rest});

export const navigateToDisconnectDestination = (
    disconnectDestination: string,
    fallbackRedirect: () => void,
) => {
    const result = validateDisconnectDestination(disconnectDestination);
    if (result.isValid) {
        window.location.href = result.url.href;
    } else {
        logger.error(result.reason);
        fallbackRedirect();
    }
};

export const navigateTo = (path: string, disconnectDestination?: string) => {
    if (disconnectDestination) {
        navigateToDisconnectDestination(disconnectDestination, () =>
            push(path),
        );
    } else {
        push(path);
    }
};

export const navigateToPostMeeting = (
    conferenceAlias: string,
    disconnectDestination?: string,
) => {
    navigateTo(
        formatPath`/m/${conferenceAlias}/post-meeting`,
        disconnectDestination,
    );
};
