import {useCallback, useRef} from 'react';

export const useDoubleTap = (
    callback: (e: React.PointerEvent) => void,
    tapTimeout: number = 300,
) => {
    const lastTapTime = useRef(0);

    const onDoubleTap = useCallback(
        (e: React.PointerEvent) => {
            e.preventDefault();
            const currentTime = Date.now();
            const timeDifference = currentTime - lastTapTime.current;

            if (lastTapTime.current > 0 && timeDifference < tapTimeout) {
                callback(e);
            }

            lastTapTime.current = currentTime;
        },
        [callback, tapTimeout],
    );

    return onDoubleTap;
};
