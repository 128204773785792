interface ValidDisconnectDestination {
    isValid: true;
    url: URL;
}

interface InvalidDisconnectDestination {
    isValid: false;
    reason: string;
}

type DisconnectDestinationValidationResult =
    | ValidDisconnectDestination
    | InvalidDisconnectDestination;

/**
 * Validate the disconnect destination URL.
 * @param urlCandidate The URL to validate.
 * @returns The validation result. If the URL is valid, the URL object is returned. If the URL is invalid, the reason is returned.
 * @example validateDisconnectDestination('https://example.com')
 */
export const validateDisconnectDestination = (
    urlCandidate: string,
): DisconnectDestinationValidationResult => {
    try {
        // Try to parse the string to URL object
        const url = new URL(urlCandidate);
        // Check if the protocol is http or https
        if (url.protocol !== 'https:' && url.protocol !== 'http:') {
            return {
                isValid: false,
                reason: 'Invalid protocol. Disconnect destination needs to have `http` or `https` protocol.',
            };
        }
        // If the URL is valid, return the URL object
        return {isValid: true, url};
    } catch {
        // If the string cannot be parsed to an URL object, it is invalid
        return {
            isValid: false,
            reason: 'Invalid URL. Disconnect destination needs to be a valid URL.',
        };
    }
};
