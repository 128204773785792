import React, {useCallback, useLayoutEffect, useRef, useState} from 'react';
import cx from 'classnames';

import {useIsInWindowBottomHalf, useIsInWindowRightHalf} from '@pexip/hooks';
import {type TooltipPosition} from '@pexip/components';

import type {InMeetingDraggablePosition} from '../../InMeetingDraggable/InMeetingDraggable.view';
import {InMeetingDraggable} from '../../InMeetingDraggable/InMeetingDraggable.view';
import type {FoldedSelfviewProps} from '../FoldedSelfview/FoldedSelfview.view';
import {FoldedSelfview} from '../FoldedSelfview/FoldedSelfview.view';
import type {InMeetingSelfviewProps} from '../InMeetingSelfview/InMeetingSelfview.view';
import {InMeetingSelfview} from '../InMeetingSelfview/InMeetingSelfview.view';
import type {AutoHideButtonCallbacks} from '../../../types';
import {useDoubleTap} from '../../../hooks/useDoubleTap';

import styles from './DraggableFoldableInMeetingSelfview.module.scss';

export const DraggableFoldableInMeetingSelfview: React.FC<
    InMeetingSelfviewProps &
        FoldedSelfviewProps & {
            autoHideProps: AutoHideButtonCallbacks;
            draggableAriaLabel: string;
            isFolded: boolean;
            floatRoot?: React.RefObject<HTMLDivElement>;
            isSidePanelVisible: boolean;
        }
> = ({
    isFolded,
    draggableAriaLabel,
    floatRoot,
    isSidePanelVisible,
    autoHideProps,
    ...props
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const alignWithFloatRoot = useRef<(() => void) | null>(null);

    const {isInBottomHalf, update: updateIsInBottomHalf} =
        useIsInWindowBottomHalf();
    const {isInRightHalf, update: updateIsInRightHalf} =
        useIsInWindowRightHalf();

    const [isSelfviewMaximized, setIsSelfviewMaximized] = useState(false);
    const onDoubleTap = useDoubleTap(e => {
        e.stopPropagation();
        setIsSelfviewMaximized(current => !current);
    });

    const onPositionChangeCb = useCallback(
        (el: HTMLDivElement, position: InMeetingDraggablePosition) => {
            updateIsInBottomHalf(el);
            updateIsInRightHalf(el);
            if (position.floatRootOverflow.right) {
                el.style.removeProperty('left');
            }
        },
        [updateIsInBottomHalf, updateIsInRightHalf],
    );

    useLayoutEffect(() => {
        // using layout effect here because we are fiddling with the element's dimensions
        // https://kentcdodds.com/blog/useeffect-vs-uselayouteffect
        if (ref.current && floatRoot?.current && alignWithFloatRoot.current) {
            alignWithFloatRoot.current();
        }
    }, [floatRoot, isFolded]);

    useLayoutEffect(() => {
        ref.current?.style.removeProperty('top');
        ref.current?.style.removeProperty('bottom');
        ref.current?.style.removeProperty('left');
        ref.current?.style.removeProperty('right');
        ref.current?.style.removeProperty('transform');
    }, [isSelfviewMaximized]);

    const calculateOrientation = () => {
        return isInBottomHalf
            ? isInRightHalf
                ? 'topLeft'
                : 'topRight'
            : isInRightHalf
              ? 'bottomLeft'
              : 'bottomRight';
    };

    const getClassNames = useCallback(
        () =>
            isFolded
                ? {[styles.foldedSelfviewWrapper]: true}
                : {
                      [styles.pip]: true,
                      [styles.maximized]: isSelfviewMaximized,
                  },
        [isFolded, isSelfviewMaximized],
    );

    const notificationTooltipPos: TooltipPosition = calculateOrientation();
    const selfviewBtnTooltipPos: TooltipPosition = calculateOrientation();

    const {
        isAudioInputMuted,
        isVideoInputMuted,
        isAudioInputMissing,
        isVideoInputMissing,
        onToggleVideoClick,
        onExpandSelfview,
        onToggleAudioClick,
        showSelfviewTooltip,
        setShowSelfviewTooltip,
        ...inMeetingSelfviewProps
    } = props;

    const foldedSelfviewProps = {
        isAudioInputMuted,
        isVideoInputMuted,
        isAudioInputMissing,
        isVideoInputMissing,
        onToggleVideoClick,
        onExpandSelfview,
        onToggleAudioClick,
        showSelfviewTooltip,
        setShowSelfviewTooltip,
    };

    return (
        <>
            {isSelfviewMaximized && <div className={styles.maximizedMask} />}
            <InMeetingDraggable
                isDisabled={isSelfviewMaximized}
                draggableAriaLabel={draggableAriaLabel}
                isMoveableWithArrowKeys
                showFocus={false}
                floatRoot={floatRoot}
                isSidePanelVisible={isSidePanelVisible}
                isPhone={props.isMobileDevice}
                onRepositionSideEffect={onPositionChangeCb}
                shouldCaptureClick={false}
                className={cx(
                    styles.inMeetingSelfviewPosition,
                    getClassNames(),
                )}
                ref={ref}
                alignWithFloatRootTrigger={alignWithFloatRoot}
                autoHideProps={autoHideProps}
            >
                {isFolded ? (
                    <FoldedSelfview
                        {...foldedSelfviewProps}
                        notificationTooltipPos={notificationTooltipPos}
                        selfviewBtnTooltipPos={selfviewBtnTooltipPos}
                    />
                ) : (
                    <InMeetingSelfview
                        isAudioInputMuted={isAudioInputMuted}
                        isAudioInputMissing={isAudioInputMissing}
                        isVideoInputMuted={isVideoInputMuted}
                        onToggleAudioClick={onToggleAudioClick}
                        {...inMeetingSelfviewProps}
                        onPointerDownCapture={
                            props.isMobileDevice
                                ? e => onDoubleTap(e)
                                : undefined
                        }
                        isMaximized={isSelfviewMaximized}
                        setIsMaximized={setIsSelfviewMaximized}
                    />
                )}
            </InMeetingDraggable>
        </>
    );
};

export type DraggableFoldableInMeetingSelfviewProps = React.ComponentProps<
    typeof DraggableFoldableInMeetingSelfview
>;
