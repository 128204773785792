import type {RPCCall, RPCReply} from '@pexip/plugin-api';

import {setApplicationConfig} from '../applicationConfig';
import {validateDisconnectDestination} from '../utils/validateDisconnectDestination';

export const handleSetDisconnectDestination = (
    data: RPCCall<'app:setDisconnectDestination'>,
): Omit<RPCReply<'app:setDisconnectDestination'>, 'chanId'> => {
    const replyMeta = {
        rpc: data.rpc,
        replyTo: data.id,
    };

    const result = validateDisconnectDestination(data.payload.url);

    if (result.isValid) {
        setApplicationConfig('disconnectDestination', data.payload.url);
        return {
            ...replyMeta,
            payload: {
                status: 'ok',
                id: data.id,
                data: undefined,
            },
        };
    } else {
        return {
            ...replyMeta,
            payload: {
                status: 'failed',
                id: data.id,
                reason: result.reason,
            },
        };
    }
};
